import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, Typography, Stack, IconButton, InputAdornment, TextField, Link, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import Logo from '../logo/Logo';
import Iconify from '../iconify/Iconify';
import useResponsive from '../../hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function SignupComponent() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [otpMode, setOtpMode] = useState(false);
  const [otp, setOtp] = useState('');
  const [account, setAccount] = useState({ email: '', password: '', password_confirmation: '', name: '', role: 'admin' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccount({ ...account, [name]: value });
  };

  const handleRegister = () => {
    setShowLoader(true);
    api.post('/users', { user: account })
      .then((response) => {
        setShowLoader(false);
        if (response.data.message) {
          toast.success("OTP sent! Please verify.");
          setOtpMode(true);
        } else if (response.data.error) {
          toast.error(response.data.error);
        }
      })
      .catch(() => {
        setShowLoader(false);
        toast.error("Registration failed.");
      });
  };

  const handleVerifyOtp = () => {
    setShowLoader(true);
    api.post('/verify-otp', { email: account.email, 
        otp,
        user: {
            email:account.email,
          name: account.name,
          password: account.password,
          password_confirmation: account.password_confirmation,
          role:account.role
        } })
      .then((response) => {
        setShowLoader(false);
        if (response.data.success) {
          toast.success("OTP verified! Logging in...");
          navigate('/login');
        } else {
          toast.error("Invalid OTP. Try again.");
        }
      })
      .catch(() => {
        setShowLoader(false);
        toast.error("OTP verification failed.");
      });
  };

  return (
    <>
      <Helmet>
        <title>City Courier - Register</title>
      </Helmet>

      <StyledRoot>
        <Logo sx={{ position: 'fixed', top: { xs: 16, sm: 24, md: 40 }, left: { xs: 16, sm: 24, md: 40 } }} />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, {otpMode ? "Verify Your OTP" : "Register as Admin in City Courier Services"}
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {otpMode ? "Verify OTP" : "Register"}
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Already have an account? <Link variant="subtitle2"  href="/login">Login</Link>
            </Typography>

            {otpMode ? (
              <Stack spacing={3} mb={3}>
                <TextField name="otp" label="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
                <LoadingButton
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleVerifyOtp}
                  disabled={showLoader}
                >
                  {showLoader ? <CircularProgress size={24} /> : "Verify OTP"}
                </LoadingButton>
              </Stack>
            ) : (
              <Stack spacing={3} mb={3}>
                <TextField name="name" label="Full Name" onChange={handleChange} />
                <TextField name="email" label="Email address" onChange={handleChange} />
                <TextField
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  name="password_confirmation"
                  label="Confirm Password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <LoadingButton
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleRegister}
                  disabled={showLoader}
                >
                  {showLoader ? <CircularProgress size={24} /> : "Register"}
                </LoadingButton>
              </Stack>
            )}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
