import React, { useEffect, useState, useContext, useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import QRCode from 'react-qr-code';
import {
    Container,
    Stack,
    Typography,
    Button,
    TextField,
    Select,
    InputLabel,
    MenuItem,
    Autocomplete,
    Tabs,
    Tab,
    IconButton,
} from '@mui/material'

export default function PrintQRComponent({showResponseData}) {
    const contentRef = useRef(null);
    const [back, setBack] = useState('#FFFFFF');
    const [fore, setFore] = useState('#000000');
    const [size, setSize] = useState(100);



    return (
    <>
        {showResponseData  && showResponseData.consignmentNo &&
            <Stack direction="column" alignItems="center"  mb={5}>
                <QRCode
                        title={showResponseData && showResponseData.consignmentNo || 'No consignment number'}
                        value={showResponseData && showResponseData.consignmentNo || 'No consignment number'}
                        bgColor={back}
                        fgColor={fore}
                        size={size === '' ? 0 : size}
                    />
            
                <Typography variant="h5" gutterBottom>
                    {showResponseData.consignmentNo}
                </Typography>

            </Stack>}
            
            {showResponseData && showResponseData.consignmentNo && showResponseData.sub_packages.length > 0 &&
                showResponseData.sub_packages.map((packageItem) => (
                    <Stack direction="column" alignItems="center" mb={5} key={packageItem.label}>
                        <QRCode
                            title={packageItem.label}
                            value={packageItem.label}
                            bgColor={back}
                            fgColor={fore}
                            size={size === '' ? 0 : size}
                        />
                        <Typography variant="h5" gutterBottom>
                            {packageItem.label}
                        </Typography>
                    </Stack>
                ))
            }

    </>
    )
}