import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage'
import UserPage from './components/users-component/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import LoginComponent from './components/login-component/login';
import AddClientComponent from './components/client-component/addClient';
import AdminDashboard from './components/dashboard-component/admin-dashboard';
import ClientsComponent from './components/client-component/clients';
import AddPackageComponent from './components/package-component/addPackage';
import AddEmployeeComponent from './components/employee-component/addEmployee';
import EmployeesComponent from './components/employee-component/employees';
import PackagesComponent from './components/package-component/packageList';
import PrintInvoiceComponent from './components/package-component/printInvoice';
import SignupComponent from './components/login-component/signup';



// ----------------------------------------------------------------------

export default function Router() {
  const isLoggedIn = JSON.parse(localStorage.getItem('user')) ? true : false;
  const routes = useRoutes([
    // {
    //   path: '/dashboard',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/user" />, index: true },
    //     { path: 'admin', element: <DashboardAppPage /> },
    //     { path: 'user', element: <UserPage /> },
    //     { path: 'products', element: <ProductsPage /> },
    //     { path: 'blog', element: <BlogPage /> },
    //   ],
    // },
    isLoggedIn && {
      path: '',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard-admin', element: <AdminDashboard />, index: true },
        { path: 'add-client', element: <AddClientComponent />, index: true },
        { path: 'clients', element: <ClientsComponent />, index: true },
        { path: 'add-package', element: <AddPackageComponent />, index: true },
        { path: 'add-employee', element: <AddEmployeeComponent />, index: true },
        { path: 'employees', element: <EmployeesComponent />, index: true },
        { path: 'packages', element: <PackagesComponent />, index: true },
        { path: 'print-invoice', element: <PrintInvoiceComponent />, index: true },





        { path: 'user', element: <UserPage />, index: true },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> }
       
      ],
    },
    {
      path: 'login',
      element: <LoginComponent />,
    },
    {
      path: 'signup',
      element: <SignupComponent/>,
    },
    {
      path: '*',
      element: <LoginComponent />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '404', element: <Page404 /> },
      
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);


  
  return routes;
}
