import React, { useEffect, useState, useContext, useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import QRCode from 'react-qr-code';

import {
    Container,
    Stack,
    Typography,
    Button,
    TextField,
    Select,
    InputLabel,
    MenuItem,
    Autocomplete,
    Tabs,
    Tab,
    IconButton,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment';
import api from '../../api';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from '../iconify/Iconify';
import PrintQRComponent from './printQR';
import PrintInvoiceComponent from './printInvoice';

export default function AddPackageComponent() {

    const users = JSON.parse(localStorage.getItem('user'));
    const { state } = useLocation();
    const navigate = useNavigate();
    const [showClients, setClients] = useState([])
    const [showQR, setShowQR] = useState(false)
    const [showInvoice, setShowInvoice] = useState(false)

    // const [showResponseData, setResponseData] = useState({consignmentNo:"df", sub_packages:[]})
    const [showResponseData, setResponseData] = useState()



    const [showClientSelected, setClientSelected] = useState([])
    const [showPackageType, setPackageType] = useState([
        { label: 'Local', value: 'Local' },
        { label: 'State', value: 'State' },
        { label: 'National', value: 'National' },
        { label: 'International', value: 'International' },

    ])
    const [show, setShow] = useState(true)
    const [show2, setShow2] = useState(false)

    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState(-1);
    // const reactToPrintFn = useReactToPrint({ contentRef });
    const [showPackage, setPackage] = useState({
        clientName: '',
        senderName: '',
        gstNo: '',
        senderPinCode: '',
        senderAddress: '',
        senderDistrict: '',
        senderState: '',
        senderCountry: '',
        senderContactNo: '',
        senderEmail: '',
        senderWhatsappNo: '',
        consigneeName: '',
        receiverName: "",
        consigneePincode: '',
        consigneeAddress: '',
        consigneeDistrict: '',
        consigneeState: '',
        consigneeCountry: '',
        consigneeContactNo: '',
        consigneeEmail: '',
        consigneeWhatsappNo: '',
        packageType: 'Local',
        weight: '100',
        amount: '0',
        discount: '0',
        totalAmount: '0',
        adminId: '',
        employeeName: '',
        emloyeeId: '',
        cGSTAmount: '',
        sGSTAmount: '',
        iGSTAmount: '',
        totalGST: '0',
        subPackages: []


    })

    const [showPackageError, setPackageError] = useState({
        clientName: '',
        senderName: '',
        gstNo: '',
        senderPinCode: '',
        senderAddress: '',
        senderDistrict: '',
        senderState: '',
        senderCountry: '',
        senderContactNo: '',
        senderEmail: '',
        senderWhatsappNo: '',
        consigneeName: '',
        consigneePincode: '',
        consigneeAddress: '',
        consigneeDistrict: '',
        consigneeState: '',
        consigneeCountry: '',
        consigneeContactNo: '',
        consigneeEmail: '',
        consigneeWhatsappNo: '',
        packageType: '',
        weight: '',
        amount: '',
        discount: '',
        totalAmount: '',
        totalGST:"",
        adminId: '',
        employeeName: '',
        emloyeeId: ''

    })
    const [showCalculation, setCalculation] = useState({
        localRate:"",
        stateRate:"",
        nationalRate:"",
        interNationalRate:""
    })
    const [showSubPackage, setSubPackage] = useState({
        weight: '',
    })

    useEffect(() => {
        getClients()
    }, []);

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleAddTab = () => {
        const newTabs = [...tabs, { label: `Package ${tabs.length + 1}`, data: `Data ${tabs.length + 1}` }];
        setTabs(newTabs);
        setActiveTab(newTabs.length - 1);
    };

    const handleDeleteTab = (index) => {
        const newTabs = tabs.filter((tab, i) => i !== index);
        setTabs(newTabs);

        // Adjust activeTab if the deleted tab is the active tab
        if (index === activeTab && newTabs.length > 0) {
            const newActiveTab = index > 0 ? index - 1 : 0;
            setActiveTab(newActiveTab);
        }
    };
    const handleDataChange = (index, value) => {
        const newTabs = [...tabs];
        newTabs[index].data = value;
        setTabs(newTabs);
    };
    function getClients() {

        api.get('/api/v1/clients')
            .then((response) => {
                if (response.data.success) {
                    let temp = []
                    response.data.clients.map((item) => {
                        temp.push({ label: item.name + ` (${item.gstNo})`, value: item })
                    })
                    temp.push({ label: 'General', value: '' })
                    setClients([...temp]);
                    // setClients([...response.data.clients])
                }
                else {
                    toast.error(response.data.message);
                }
            })
            .catch(() => {
                toast.error('Something went wrong!');
            });
    }


    const onClientChange = (event, values) => {
        setClientSelected(values.value)

        if (values.label == "General") {
            setShow(true)
            setPackage({
                ...showPackage,
                clientName: "General",
                senderName: '',
                gstNo: '',
                senderPinCode: '',
                senderAddress: '',
                senderDistrict: '',
                senderState: '',
                senderCountry: '',
                senderContactNo: '',
                senderEmail: '',
                senderWhatsappNo: '',


            })
        }
        else {
            setShow(false)
            setPackage({
                ...showPackage,
                clientName: values.value.name,
                senderName: '',
                gstNo: values.value.gstNo,
                senderPinCode: values.value.pinCode,
                senderAddress: values.value.address,
                senderDistrict: values.value.district,
                senderState: values.value.state,
                senderCountry: values.value.country,
                senderContactNo: values.value.contactNo,
                senderEmail: values.value.email,
                senderWhatsappNo: values.value.whatsappNo,


            })
            setCalculation({
                ...showCalculation,
                localRate: values.value.localRate,
                stateRate: values.value.stateRate,
                nationalRate: values.value.nationalRate,
                interNationalRate: values.value.interNationalRate
            })
           
        }

    }

    useEffect(() => {
        calculateAmount()
    }, [showCalculation]);

    useEffect(() => {
        let changeAmount = parseFloat(showPackage.totalAmount)- parseFloat(showPackage.discount )
        if(changeAmount < 0){
            setPackageError({...showPackageError, totalAmount: "Total Amount cannot be negative", discount: "This discount amount is not valid"})
            setPackage({...showPackage, totalAmount:changeAmount})

        }else if(showPackage.discount == "" || showPackage.discount < 0 ){
            setPackageError({...showPackageError, totalAmount: "", discount: "Enter a valid discount"})
            calculateAmount(showPackage.packageType)
        }
        else{
            setPackageError({...showPackageError, totalAmount: "", discount: ""})
            setPackage({...showPackage, totalAmount:changeAmount})
        }
    }, [showPackage.discount]);

    const onConsigneeChange = (event, values) => {


        if (values.label == "General") {
            setShow2(true)
            setPackage({
                ...showPackage,
                consigneeName: 'General',
                consigneePincode: '',
                consigneeAddress: '',
                consigneeDistrict: '',
                consigneeState: '',
                consigneeCountry: '',
                consigneeContactNo: '',
                consigneeEmail: '',
                consigneeWhatsappNo: '',

            })
        }
        else {
            setShow2(false)
            setPackage({
                ...showPackage,

                consigneeName: values.value.name,
                consigneePincode: values.value.pinCode,
                consigneeAddress: values.value.address,
                consigneeDistrict: values.value.district,
                consigneeState: values.value.state,
                consigneeCountry: values.value.country,
                consigneeContactNo: values.value.contactNo,
                consigneeEmail: values.value.email,
                consigneeWhatsappNo: values.value.whatsappNo,


            })
        }
    }

    async function pincCode(value, type) {
        const response = await fetch(`https://api.postalpincode.in/pincode/${value}`);
        var data = await response.json();
        if (response) {
            if (data[0].Status == "Error") {
                setPackageError({
                    ...showPackageError,
                    senderPinCode:
                        value.length == 6 ? '' : 'Correct pincode is required!'
                });

            }
            else if (type == "consignee") {
                setPackage({ ...showPackage, consigneeState: data[0].PostOffice[0].State, consigneeDistrict: data[0].PostOffice[0].District, consigneeCountry: data[0].PostOffice[0].Country })

            }
            else if (type == "sender") {
                setPackage({ ...showPackage, senderState: data[0].PostOffice[0].State, senderDistrict: data[0].PostOffice[0].District, senderCountry: data[0].PostOffice[0].Country })

            }
        }

    };

    const onClickPackageType = (event, values) => {
        setPackage({ ...showPackage, packageType: values?.label || '' });
        calculateAmount(values?.label)
    };
    
    const calculateAmount = (value) => {
        let type = value ? value : 'Local'
        let amount = 0;
        switch (type) {
            case 'Local':
                amount = calculateTotal(showCalculation.localRate);
                break;
            case 'State':
                amount = calculateTotal(showCalculation.stateRate);
                break;
            case 'National':
                amount = calculateTotal(showCalculation.nationalRate);
                break;
            case 'International':
                amount = calculateTotal(showCalculation.interNationalRate);
                break;
            default:
                amount = 0;
        }
    
        setPackage((prevPackage) => ({
            ...prevPackage,
            totalAmount: amount,
        }));
    };
    
    const calculateTotal = (value) => {
        const weight = parseFloat(showPackage.weight) || 0;
        const discount = parseFloat(showPackage.discount) || 0;
        const rate = parseFloat(value) || 0;
    
        const amountWithoutGst = rate * weight;
        const amountWithGst = (rate * weight * 18) / 100;
        const total = amountWithoutGst + amountWithGst - discount;
    
        setPackage((prevPackage) => ({
            ...prevPackage,
            amount: amountWithoutGst,
            totalGST: amountWithGst,
        }));
    
        return total;
    };
    
   

    const onClickCancel = () => {

    }

    const onChangePackage = (event) => {
        const { name, value } = event.target;
        var regmob = new RegExp('^([0|+[0-9]{1,5})?([7-9][0-9]{9})$');
        let reemail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        switch (name) {
            case 'consigneeName': setPackageError({
                ...showPackageError,
                consigneeName:
                    value.length > 1 ? '' : 'Minimum two digit is required!'
            });
                break;
            case 'gstNo': setPackageError({
                ...showPackageError,
                gstNo:
                    value.length > 1 ? '' : 'Minimum two digit is required!'
            });
                break;
            case 'consigneeAddress': setPackageError({
                ...showPackageError,
                consigneeAddress:
                    value.length > 1 ? '' : 'Full address is required!'
            });
                break;

            case 'consigneePincode': setPackageError({
                ...showPackageError,
                consigneePincode:
                    value.length == 6 ? '' : 'Correct pincode is required!'
            });
                value.length == 6 && pincCode(value, "consignee");
                break;

            case 'consigneeDistrict': setPackageError({
                ...showPackageError,
                consigneeDistrict:
                    value.length > 1 ? '' : 'Minimum two digit is required!'
            });
                break;

            case 'consigneeState': setPackageError({
                ...showPackageError,
                consigneeState:
                    value.length > 1 ? '' : 'Minimum two digit is required!'
            });
                break;

            case 'consigneeCountry': setPackageError({
                ...showPackageError,
                consigneeCountry:
                    value.length > 1 ? '' : 'Minimum two digit is required!'
            });
                break;

            case 'consigneeContactNo': setPackageError({
                ...showPackageError,
                consigneeContactNo:
                    regmob.test(value) ? '' : 'Incorrect mobile number!'
            });
                break;
            case 'consigneeEmail': setPackageError({
                ...showPackageError,
                consigneeEmail: reemail.test(String(value).toLowerCase()) ? '' : 'Email is incorrect!'
            });
                break;
            case 'consigneeWhatsappNo': setPackageError({
                ...showPackageError,
                consigneeWhatsappNo:
                    regmob.test(value) ? '' : 'Incorrect mobile number!'
            });
                break;
            case 'senderAddress': setPackageError({
                ...showPackageError,
                senderAddress:
                    value.length > 1 ? '' : 'Full address is required!'
            });
                break;
            case 'senderPinCode': setPackageError({
                ...showPackageError,
                senderPinCode:
                    value.length == 6 ? '' : 'Correct pincode is required!'
            });
                value.length == 6 && pincCode(value, "sender");
                break;
            case 'senderDistrict': setPackageError({
                ...showPackageError,
                senderDistrict:
                    value.length > 1 ? '' : 'Minimum two digit is required!'
            });
                break;
            case 'senderState': setPackageError({
                ...showPackageError,
                senderState:
                    value.length > 1 ? '' : 'Minimum two digit is required!'
            });
                break;

            case 'senderCountry': setPackageError({
                ...showPackageError,
                senderCountry:
                    value.length > 1 ? '' : 'Minimum two digit is required!'
            });
                break;

            case 'senderContactNo': setPackageError({
                ...showPackageError,
                consigneeContactNo:
                    regmob.test(value) ? '' : 'Incorrect mobile number!'
            });
                break;
            case 'senderEmail': setPackageError({
                ...showPackageError,
                consigneeEmail: reemail.test(String(value).toLowerCase()) ? '' : 'Email is incorrect!'
            });
                break;
            case 'senderWhatsappNo': setPackageError({
                ...showPackageError,
                senderWhatsappNo:
                    regmob.test(value) ? '' : 'Incorrect mobile number!'
            });
                break;
            case 'weight': setPackageError({
                ...showPackageError,
                weight:
                   value.length > 1 ? '' : 'Add Weight'
            });
                break;
            case 'amount': setPackageError({
                ...showPackageError,
                amount:
                    parseFloat(value) > 0 ? '' : 'Enter Correct Amount'
            });
                break;
            
            case 'totalGST': setPackageError({
                ...showPackageError,
                totalGST:
                    parseFloat(value) >= 0 ? '' : 'Enter Correct Gst Amount'
            });
                    break;

            case 'discount': setPackageError({
                ...showPackageError,
                discount:
                    parseFloat(value) >= 0 ? '' : 'Enter Correct Discount Amount'
            });
                    break;
            
            case 'totalAmount': setPackageError({
                ...showPackageError,
                totalAmount:
                    parseFloat(value) > 0 ? '' : 'Enter Correct Total Amount'
            });
                    break;
        }
        showPackage[name] =  value;
        setPackage(showPackage);
    }

    const onClickAddPackage = () => {
        const data = {
            consigneeName: showPackage.consigneeName == "General" ? showPackage.receiverName : showPackage.consigneeName,
            consigneeAddress: showPackage.consigneeAddress,
            consigneePinCode: showPackage.consigneePincode,
            consigneeDistrict: showPackage.consigneeDistrict,
            consigneeState: showPackage.consigneeState,
            consigneeCountry: showPackage.consigneeCountry,
            consigneeContactNo: showPackage.consigneeContactNo,
            consigneeEmail: showPackage.consigneeEmail,
            consigneeWhatsappNo: showPackage.consigneeWhatsappNo,

            senderName: showPackage.clientName == "General" ? showPackage.senderName : showPackage.clientName,
            sub_packages: tabs,
            gstNo: showPackage.gstNo,
            senderPinCode: showPackage.senderPinCode,
            senderAddress: showPackage.senderAddress,
            senderDistrict: showPackage.senderDistrict,
            senderState: showPackage.senderState,
            senderCountry: showPackage.senderCountry,
            senderContactNo: showPackage.senderContactNo,
            senderEmail: showPackage.senderEmail,
            senderWhatsappNo: showPackage.senderWhatsappNo,

            weight: showPackage.weight,
            discount: showPackage.discount,
            totalAmount: showPackage.totalAmount,
            packageType: showPackage.packageType
        }
        api
            .post('/api/v1/packages', {
                data
            })
            .then((response) => {
                if (response.data.success) {
                    const currentUser = response.data;

                    //   localStorage.setItem('token', response.data.token);
                    //   localStorage.setItem('user', JSON.stringify(response.data.data));

                    //window.location.href = '/dashboard-admin';
                    // navigate("/dashboard-admin");
                    setResponseData(response.data.package)
                    setShowQR(true)
                    // printQR()
                }
                else if (response.data.message === "Your account was de-activated, please contact support!") {


                }
                else {
                }
            })
            .catch(() => {
            });

    }

    const contentRef = useRef(null);
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);
    const handlePrintQR = useReactToPrint({ contentRef,  onBeforePrint: () => new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                console.log("print start")

                setIsPrinting(true);
            }),
            onAfterPrint: () => {
                promiseResolveRef.current = null;
                console.log("print complete")

                setIsPrinting(false);
                setShowInvoice(true)
                setShowQR(false)


            }})
    
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);
    

    useEffect(() => {
        if (showQR) {
            handlePrintQR(); // Start QR print when showQR is true
        }
    }, [showQR == true]);
  
    
    const contentRefInvoice = useRef(null);
    const [isPrintingInvoice, setIsPrintingInvoice] = useState(false);
    const promiseResolveRefInvoice = useRef(null);
    const handlePrintInvoice = useReactToPrint({ contentRef,  onBeforePrint: () => new Promise((resolve) => {
                promiseResolveRefInvoice.current = resolve;
                console.log("print start")

                setIsPrintingInvoice(true);
            }),
            onAfterPrint: () => {
                promiseResolveRefInvoice.current = null;
                console.log("print complete")
                setShowQR(false)
                setShowInvoice(false)
                setIsPrintingInvoice(false);
            }})
    
    useEffect(() => {
        if (isPrintingInvoice && promiseResolveRefInvoice.current) {
            promiseResolveRefInvoice.current();
        }
    }, [isPrintingInvoice]);
    

    useEffect(() => {
        if (showInvoice) {
            handlePrintInvoice(); // Start Invoice print when showInvoice is true
        }
    }, [showInvoice ===true]);

    
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Add Package
                    </Typography>

                </Stack>

                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>

                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={showClients}
                        style={{ marginRight: "10px" }}
                        onChange={onConsigneeChange}
                        fullWidth
                        value={showPackage.consigneeName}
                        renderInput={(params) => <TextField  {...params} label="Consignee Name" />}
                    />

                    {/* <TextField id="outlined-basic" fullWidth label="Consignee Name" variant="outlined" style={{ marginRight: "10px" }}
                        name='consigneeName'
                        error={showPackageError.consigneeName.length > 0 ? true : false}
                        helperText={showPackageError.consigneeName.length > 0 ? showPackageError.consigneeName : ''}
                        onChange={onChangePackage}

                    /> */}
                    {show2 && <TextField id="outlined-basic" name='receiverName' fullWidth label="Receiver Name" onChange={onChangePackage} variant="outlined" style={{ marginRight: "10px" }}

                    />}
                    <TextField id="outlined-basic" fullWidth label=" Consignee Address" variant="outlined" style={{ marginRight: "10px" }}
                        name='consigneeAddress'
                        error={showPackageError.consigneeAddress.length > 0 ? true : false}
                        helperText={showPackageError.consigneeAddress.length > 0 ? showPackageError.consigneeAddress : ''}
                        onChange={onChangePackage}
                        value={showPackage.consigneeAddress}

                    />
                </Stack>

                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField fullWidth id="outlined-basic" label="Consignee Pin Code" variant="outlined" style={{ marginRight: "10px" }}
                        name='consigneePincode'
                        type='number'
                        error={showPackageError.consigneePincode.length > 0 ? true : false}
                        helperText={showPackageError.consigneePincode.length > 0 ? showPackageError.consigneePincode : ''}
                        onChange={onChangePackage}
                        value={showPackage.consigneePincode}

                    />
                    <TextField fullWidth id="outlined-basic" label="Consignee District" variant="outlined" style={{ marginRight: "10px" }}
                        name='consigneeDistrict'
                        onChange={onChangePackage}
                        value={showPackage.consigneeDistrict}
                        error={showPackageError.consigneeDistrict.length > 0 ? true : false}
                        helperText={showPackageError.consigneeDistrict.length > 0 ? showPackageError.consigneeDistrict : ''}

                    />
                    <TextField id="outlined-basic" fullWidth label="Consignee State" variant="outlined" style={{ marginRight: "10px" }}
                        name='consigneeState'
                        onChange={onChangePackage}
                        value={showPackage.consigneeState}
                        error={showPackageError.consigneeState.length > 0 ? true : false}
                        helperText={showPackageError.consigneeState.length > 0 ? showPackageError.consigneeState : ''}



                    />
                    <TextField fullWidth id="outlined-basic" label="Consignee Country" variant="outlined" style={{ marginRight: "10px" }}
                        name='consigneeCountry'
                        onChange={onChangePackage}
                        value={showPackage.consigneeCountry}
                        error={showPackageError.consigneeCountry.length > 0 ? true : false}
                        helperText={showPackageError.consigneeCountry.length > 0 ? showPackageError.consigneeCountry : ''}



                    />
                </Stack>


                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField id="outlined-basic" fullWidth label="Consignee Contact No" variant="outlined" style={{ marginRight: "10px" }}
                        type='number'
                        name='consigneeContactNo'
                        onChange={onChangePackage}
                        value={showPackage.consigneeContactNo}
                        error={showPackageError.consigneeContactNo.length > 0 ? true : false}
                        helperText={showPackageError.consigneeContactNo.length > 0 ? showPackageError.consigneeContactNo : ''}

                    />
                    <TextField fullWidth id="outlined-basic" label="Consignee Email" variant="outlined" style={{ marginRight: "10px" }}
                        name='consigneeEmail'
                        type='email'
                        onChange={onChangePackage}
                        value={showPackage.consigneeEmail}
                        error={showPackageError.consigneeEmail.length > 0 ? true : false}
                        helperText={showPackageError.consigneeEmail.length > 0 ? showPackageError.consigneeEmail : ''}
                    />
                    <TextField id="outlined-basic" fullWidth label="Consignee Whatsapp No" variant="outlined" style={{ marginRight: "10px" }}
                        name='consigneeWhatsappNo'
                        type='number'
                        onChange={onChangePackage}
                        value={showPackage.consigneeWhatsappNo}
                        error={showPackageError.consigneeWhatsappNo.length > 0 ? true : false}
                        helperText={showPackageError.consigneeWhatsappNo.length > 0 ? showPackageError.consigneeWhatsappNo : ''}
                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }} style={{ marginTop: "50px" }}>

                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={showClients}
                        style={{ marginRight: "10px" }}
                        onChange={onClientChange}
                        fullWidth
                        value={showPackage.clientName}
                        renderInput={(params) => <TextField  {...params} label="Clients" />}
                    />
                    {show && <TextField id="outlined-basic" fullWidth label="Sender Name" variant="outlined" style={{ marginRight: "10px" }}

                    />}
                    <TextField id="outlined-basic" fullWidth label="GST No" variant="outlined" style={{ marginRight: "10px" }}
                        name='gstNo'
                        value={showPackage.gstNo}
                        error={showPackageError.gstNo.length > 0 ? true : false}
                        helperText={showPackageError.gstNo.length > 0 ? showPackageError.gstNo : ''}
                        onChange={onChangePackage}

                    />


                </Stack>
                <Stack direction="row" justifyContent="space-between" >

                    <TextField fullWidth id="outlined-basic" label="Sender Address" variant="outlined" style={{ marginRight: "10px" }}
                        name='senderAddress'
                        error={showPackageError.senderAddress.length > 0 ? true : false}
                        helperText={showPackageError.senderAddress.length > 0 ? showPackageError.senderAddress : ""}
                        value={showPackage.senderAddress} onChange={onChangePackage}

                    />
                    <TextField id="outlined-basic" fullWidth label="Sender Pincode" variant="outlined" style={{ marginRight: "10px" }}
                        name='senderPinCode'
                        error={showPackageError.senderPinCode.length > 0 ? true : false}
                        helperText={showPackageError.senderPinCode.length > 0 ? showPackageError.senderPinCode : ""}
                        value={showPackage.senderPinCode}
                        onChange={onChangePackage}
                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField id="outlined-basic" fullWidth label="Sender District" variant="outlined" style={{ marginRight: "10px" }}
                        name='senderDistrict'
                        value={showPackage.senderDistrict}
                        error={showPackageError.senderDistrict.length > 0 ? true : false}
                        helperText={showPackageError.senderDistrict.length > 0 ? showPackageError.senderDistrict : ""}
                        onChange={onChangePackage}
                    />
                    <TextField fullWidth id="outlined-basic" label="Sender State" variant="outlined" style={{ marginRight: "10px" }}
                        value={showPackage.senderState}
                        name='senderState'
                        error={showPackageError.senderState.length > 0 ? true : false}
                        helperText={showPackageError.senderState.length > 0 ? showPackageError.senderState : ""}
                        onChange={onChangePackage}

                    />
                    <TextField id="outlined-basic" fullWidth label="Sender Country" variant="outlined" style={{ marginRight: "10px" }}
                        value={showPackage.senderCountry}
                        name='senderCountry'
                        error={showPackageError.senderCountry.length > 0 ? true : false}
                        helperText={showPackageError.senderCountry.length > 0 ? showPackageError.senderCountry : ""}
                        onChange={onChangePackage}

                    />
                </Stack> <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField id="outlined-basic" fullWidth label="Sender Contact No" variant="outlined" style={{ marginRight: "10px" }}
                        value={showPackage.senderContactNo}
                        error={showPackageError.senderContactNo.length > 0 ? true : false}
                        name='senderContactNo'
                        helperText={showPackageError.senderContactNo.length > 0 ? showPackageError.senderContactNo : ""}
                        onChange={onChangePackage}


                    />
                    <TextField fullWidth id="outlined-basic" label="Sender Email" variant="outlined" style={{ marginRight: "10px" }}
                          value={showPackage.senderEmail}
                          name='senderEmail'
                          error={showPackageError.senderEmail.length > 0 ? true : false}
                          helperText={showPackageError.senderEmail.length > 0 ? showPackageError.senderEmail : ""}
                          onChange={onChangePackage}

                    />
                    <TextField id="outlined-basic" fullWidth label="Sender Whatsapp No" variant="outlined" style={{ marginRight: "10px" }}
                        name='senderWhatsappNo'
                        value={showPackage.senderWhatsappNo}
                        error={showPackageError.senderWhatsappNo.length > 0 ? true : false}
                        helperText={showPackageError.senderWhatsappNo.length > 0 ? showPackageError.senderWhatsappNo : ""}
                        onChange={onChangePackage}

                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between" style={{ marginTop: "50px" }}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        id="combo-box-demo"
                        options={showPackageType}
                        style={{ marginRight: "10px" }}
                        onChange={onClickPackageType}
                        onKeyDown={(e) => e.preventDefault()}
                        fullWidth
                        value={showPackageType.find((type) => type.label === showPackage.packageType) || null}
                        renderInput={(params) => (
                            <TextField {...params} label="Package Type" InputProps={{ ...params.InputProps, readOnly: true }} />
                        )}
                    />

                    <TextField fullWidth id="outlined-basic" label="Weight (in gm)" variant="outlined" style={{ marginRight: "10px" }}
                        name='weight'
                        type='number'
                        value={showPackage.weight}
                        onChange={onChangePackage}
                        error={showPackageError.weight.length > 0 ? true : false}
                        helperText={showPackageError.weight.length > 0 ? showPackageError.weight : ""}


                    />
                    <TextField id="outlined-basic" fullWidth label="Amount (in INR)" variant="outlined" style={{ marginRight: "10px" }}
                        name='amount'
                        type='number'
                        value={showPackage.amount}
                        onChange={onChangePackage}
                        error={showPackageError.amount.length > 0 ? true : false}
                        helperText={showPackageError.amount.length > 0 ? showPackageError.amount : ""}
                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
                    <TextField id="outlined-basic" fullWidth label="Gst Amount (cgst + sgst + igst)(in INR)" variant="outlined" style={{ marginRight: "10px" }}
                        name='totalGST'
                        type='number'
                        value={showPackage.totalGST}
                        onChange={onChangePackage}
                        error={showPackageError.totalGST.length > 0 ? true : false}
                        helperText={showPackageError.totalGST.length > 0 ? showPackageError.totalGST : ""}

                    />
                    <TextField id="outlined-basic" fullWidth label="Discount (in INR)" variant="outlined" style={{ marginRight: "10px" }}
                        name='discount'
                        type='number'
                        value={showPackage.discount}
                        onChange={onChangePackage}
                        error={showPackageError.discount.length > 0 ? true : false}
                        helperText={showPackageError.discount.length > 0 ? showPackageError.discount : ""}


                    />

                    <TextField id="outlined-basic" fullWidth label="Total Amount (in INR)" variant="outlined" style={{ marginRight: "10px" }}
                        name='totalAmount'
                        type='number'
                        value={showPackage.totalAmount}
                        onChange={onChangePackage}
                        error={showPackageError.totalAmount.length > 0 ? true : false}
                        helperText={showPackageError.totalAmount.length > 0 ? showPackageError.totalAmount : ""}

                    />
                </Stack>
                <Stack>

                    <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                label={
                                    <div>
                                        {tab.label}
                                        <IconButton key={index} onClick={() => handleDeleteTab(index)} aria-label="delete">
                                            <Iconify icon={'charm:cross'} />
                                        </IconButton>
                                    </div>
                                }
                            />
                        ))}
                        <Button variant="success" onClick={handleAddTab}>+</Button>
                    </Tabs>




                    <div>
                        {tabs.map((tab, index) => (
                            <div key={index} hidden={activeTab !== index}>
                                <textarea
                                    value={tab.data}
                                    onChange={(e) => handleDataChange(index, e.target.value)}
                                    rows={4}
                                    cols={50}
                                />
                            </div>
                        ))}
                    </div>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent={'center'} mt={5} mb={5}>
                    <Button variant="contained" style={{ marginRight: "10px" }} onClick={onClickAddPackage}>
                        Add
                    </Button>
                    <Button variant="contained" color='error' onClick={onClickCancel}>
                        Cancel
                    </Button>
                </Stack>
                     
               {showQR &&  
                    <div ref={contentRef}  >
                      <PrintQRComponent  showResponseData={showResponseData} />
                    </div>
                }

{showInvoice &&  
                    <div ref={contentRef}  >
                      <PrintInvoiceComponent  showResponseData={showResponseData} />
                    </div>
                }
               
            </Container>


        </>

    )
}
