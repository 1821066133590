import React from 'react';
import { Container, Stack, Typography, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Logo from '../../components/logo';
import QRCode from 'react-qr-code';

const tempShowResponseData = {
    adminId: 3,
    consigneeName: "rates",
    consigneeAddress: "GKP",
    consigneeContactNo: "8574794320",
    consigneeCountry: "India",
    consigneeDistrict: "GKP",
    consigneeEmail: "zaheenkasim@gmail.com",
    consigneePinCode: "273015",
    consigneeState: "GKP",
    senderName: "rates",
    senderAddress: "GKP",
    senderContactNo: "8574794320",
    senderCountry: "India",
    senderDistrict: "GKP",
    senderEmail: "zaheenkasim@gmail.com",
    senderPinCode: "273015",
    senderState: "GKP",
    consignmentNo: "2024300000055",
    totalAmount: "35400",
    weight: "100",
    sub_packages: [
        { label: "2024300000055-1", data: "Data 1" },
        { label: "2024300000055-2", data: "Data 2" },
        { label: "2024300000055-3", data: "Data 3" }
    ]
};

export default function PrintInvoiceComponent({ showResponseData  }) {
    const currentDate = new Date().toLocaleDateString();

    return (
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="center" mb={5}>
                <Box sx={{ px: 1.5, py: 1, display: 'inline-flex' }}>
                    <Logo style={{ height: '100%' }} />
                </Box>
                <Box textAlign="center">
                    <Typography variant="h3" sx={{ fontSize: '1.75rem', lineHeight: '1.5' }}>
                        City Courier Services
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontSize: '0.875rem', lineHeight: '1' }}>
                        www.citycourierservices.in
                    </Typography>
                </Box>
            </Stack>

            <Stack direction="row" spacing={5} justifyContent="space-between" mb={5}>
                <Box>
                    <Typography variant="subtitle1">Address: Venus Cinema Compound, Golghar, Gorakhpur - 273015</Typography>
                    <Typography variant="subtitle1">Phone: +91-8574794320</Typography>
                    <Typography variant="subtitle1">GST: 123456</Typography>
                </Box>
                <Box>
                    <QRCode value={showResponseData.consignmentNo} size={80} />
                </Box>
            </Stack>

            <Stack mb={5}>
                <Typography variant="subtitle2">Date: {currentDate}</Typography>
            </Stack>

            <Stack direction="row" spacing={5} justifyContent="space-between" mb={5}>
                <Box>
                    <Typography variant="h6">Consignee Information</Typography>
                    <Typography>Name: {showResponseData.consigneeName}</Typography>
                    <Typography>Address: {showResponseData.consigneeAddress}</Typography>
                    <Typography>Contact No: {showResponseData.consigneeContactNo}</Typography>
                    <Typography>Email: {showResponseData.consigneeEmail}</Typography>
                    <Typography>District: {showResponseData.consigneeDistrict}</Typography>
                    <Typography>Pin Code: {showResponseData.consigneePinCode}</Typography>
                </Box>

                <Box>
                    <Typography variant="h6">Sender Information</Typography>
                    <Typography>Name: {showResponseData.senderName}</Typography>
                    <Typography>Address: {showResponseData.senderAddress}</Typography>
                    <Typography>Contact No: {showResponseData.senderContactNo}</Typography>
                    <Typography>Email: {showResponseData.senderEmail}</Typography>
                    <Typography>District: {showResponseData.senderDistrict}</Typography>
                    <Typography>Pin Code: {showResponseData.senderPinCode}</Typography>
                </Box>
            </Stack>

            <Typography variant="h6" gutterBottom>Package Details</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Consignment Number</TableCell>
                        <TableCell>Data</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{showResponseData.consignmentNo}</TableCell>
                        <TableCell>Parent Package</TableCell>
                    </TableRow>
                    {showResponseData.sub_packages.map((pkg, index) => (
                        <TableRow key={index}>
                            <TableCell>{pkg.label}</TableCell>
                            <TableCell>{pkg.data}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Box mt={4}>
                <Typography variant="subtitle1">Total Weight: {showResponseData.weight} gm</Typography>
                <Typography variant="subtitle1">Total Amount: ₹{showResponseData.totalAmount}</Typography>
            </Box>
        </Container>
    );
}
