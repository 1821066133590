import React, { useEffect, useState } from 'react'
import api from '../../api';
import {
    Container,
    Stack,
    Typography,
    Button,
    TextField,
    Card,
    TableRow,
    TableBody,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    IconButton,
    MenuItem,
    Popover,
    TablePagination,
    Pagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@mui/material'
import Scrollbar from '../scrollbar/Scrollbar';
import Iconify from '../iconify/Iconify';
import { useNavigate } from 'react-router-dom';
import CircularProgressWithLabel from '../../utils/loader'


export default function PackagesComponent() {
    const [searchPageCount, setSearchPageCount] = useState({
        page: 1,
        total_pages: 0,
        total: 0,
        current_page: 1
    });
    const [searchQuery, setSearchQuery] = useState({
        consignmentNo: "",
        bookingDateFrom: "",
        bookingDateTo: "",
        deliveryDateFrom: "",
        deliveryDateTo:""

    });
    const [showPackges, setPackages] = useState([])
    const [showLoader, setShowLoader] = useState(false);
    const [open, setOpen] = useState(null);
    const [value, setValue] = useState([]);
    const [showPackage, setPackage] = useState(false)

    useEffect(() => {
        getPackages()
    }, []);

    function getPackages() {
        setShowLoader(true)
        api.post('/api/v1/packages/package_list', { page: searchPageCount.page, query: searchQuery })
            .then((response) => {
                if (response.data.success) {
                    setPackages([...response.data.packages])
                    setSearchPageCount(response.data.pageInfo)
                     setShowLoader(false)

                }
                else {
                    toast.error(response.data.message);
                }
            })
            .catch(() => {
                toast.error('Something went wrong!');
            });

    }

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const viewMoreResult = (event, newPage) => {
        searchPageCount.page = newPage;
        setSearchPageCount({ ...searchPageCount });
        getPackages();
    };

    const onClickEditJob = () => {
        navigate('/add-employee', { state: { showEditClient } })
    }

    const convertDate = (data) => {
        const date = new Date(data);
        
        // Extract the parts of the date
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        
        // Get the time in HH:MM AM/PM format
        const localTime = date.toLocaleTimeString("en-US", {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    
        // Format the date as DD-MM-YYYY
        const localDate = `${day}-${month}-${year}`;
        
        return `${localDate} ${localTime}`;
    };

    
    const onChangeSearch = (event) => {
        const { name, value } = event.target;
        setSearchQuery((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const onClickSearch = () => {
        getPackages();
    }


    const onClickPackage = (e,data)=>{
        e.preventDefault();
        setValue([{ ...data }]);

        setPackage(true)
    }

    const handleClose = ()=>{
        setPackage(false)
    }
    
    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                       Packages List
                    </Typography>
                </Stack>
                <Card style={{ marginBottom: "30px" }}>
                    <Stack direction="row" sx={{ my: 2 }} spacing={2}>
                        <TextField
                            label="Consignment Number"
                            name="consignmentNo"
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={onChangeSearch}
                        />
                        <TextField
                            label="Booking Date From"
                            name="bookingDateFrom"
                            type="date"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={onChangeSearch}

                        />
                        <TextField
                            label="Booking Date To"
                            name="bookingDateTo"
                            type="date"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={onChangeSearch}

                        />
                        <TextField
                            label="Delivery Date From"
                            name="deliveryDateFrom"
                            type="date"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={onChangeSearch}

                        />
                        <TextField
                            label="Delivery Date To"
                            name="deliveryDateTo"
                            type="date"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={onChangeSearch}

                        />
                        <Button variant="contained" onClick={onClickSearch} sx={{ minWidth: 120 }}>
                            Search
                        </Button>
                    </Stack>
                </Card>


                <Card>
                    {showLoader ? <CircularProgressWithLabel  /> :
                        <>
                            <TableContainer >
                                <Table sx={{ minWidth: 150 }} size="small" aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" >
                                                SNo
                                            </TableCell>
                                            <TableCell align="center" >
                                                Consignment No
                                            </TableCell>
                                            <TableCell align="center" >
                                               Consignee Address
                                            </TableCell>
                                            <TableCell align="center">
                                            Consignee Pincode
                                            </TableCell>
                                            <TableCell align="center">
                                            Consignee Phone Number
                                            </TableCell>
                                            <TableCell align="center">
                                            Sender Name
                                            </TableCell>
                                            <TableCell align="center">
                                               Weight
                                            </TableCell>
                                          
                                            <TableCell align="center">
                                                Total Amount
                                            </TableCell>
                                            <TableCell align="center">
                                                No of Packages
                                            </TableCell>
                                            <TableCell align="center">
                                                Date Of Booking
                                            </TableCell>
                                            <TableCell align="center">
                                                Date Of Delivery
                                            </TableCell>
                                            
                                            <TableCell>

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {showPackges.length > 0 && showPackges.map((packages, index) => (
                                            <TableRow hover>
                                               <TableCell align="center">
                                               {(parseInt(searchPageCount.current_page || 1) - 1) * 10 + index + 1}
                                                </TableCell>
                                                <TableCell align="center" onClick={(e)=>onClickPackage(e,packages)}> <b>{packages.consignmentNo}</b></TableCell>

                                                <TableCell align="center">
                                                   
                                                        {packages.consigneeAddress}
                                                    
                                                </TableCell>
                                                <TableCell align="center">
                                                    {packages.consigneePinCode}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {packages.consigneeContactNo}
                                                </TableCell>
                                                <TableCell align="center">
                                                {packages.senderName}({packages.senderId})
                                                </TableCell>
                                                <TableCell align="center">
                                                    {packages.weight}
                                                </TableCell>
                                    
                                                <TableCell align="center">
                                                ₹{packages.totalAmount}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {(packages.sub_packages).length}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {convertDate(packages.created_at)}
                                                </TableCell>
                                                
                                                <TableCell align="center">
                                                {packages.delivered_at ? packages.delivered_at : "NA"  }
                                                </TableCell>
                                                <TableCell align="right" onClick={() => onClickAddEditData(packages)}>
                                                    <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                                                        <Iconify icon={'eva:more-vertical-fill'} />
                                                    </IconButton>

                                                </TableCell>
                                            </TableRow>
                                        ))
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack alignItems="center" py={2}>
                                <Pagination
                                    color='primary'
                                    onChange={viewMoreResult}
                                    page={searchPageCount.current_page}
                                    count={searchPageCount.total_pages}
                                />
                            </Stack>

                            <Popover
                                open={Boolean(open)}
                                anchorEl={open}
                                onClose={handleCloseMenu}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                PaperProps={{
                                    sx: {
                                        p: 1,
                                        width: 140,
                                        '& .MuiMenuItem-root': {
                                            px: 1,
                                            typography: 'body2',
                                            borderRadius: 0.75,
                                        },
                                    },
                                }}
                            >
                                <MenuItem onClick={onClickEditJob}>
                                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                    Edit
                                </MenuItem>

                                <MenuItem sx={{ color: 'error.main' }}>
                                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                                    Delete
                                </MenuItem>
                            </Popover>
                        </>
                    }
                </Card>

                    <Dialog
                open={showPackage}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {value.map(item => (
                    <div key={item.id}>
                        <p>{item.consignmentNo}</p>
                        <p>{item.senderName}</p>
                        {/* Render other properties as needed */}
                    </div>
                    ))}

                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={handleClose} autoFocus> 
                     Agree
                </Button> 
                </DialogActions>
            </Dialog>

            </Container>


        </>
    )

}